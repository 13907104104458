.info-bubble {
  background-color: $alice-blue;
  color: $sapphire;
  padding: 17px 20px 11px;
  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }
  &__content {
    font-size: $font-size-small;
  }
  &__list {
    margin-bottom: 0;
    padding-left: 20px;
  }
}
