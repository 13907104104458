.card-shadow {
  box-shadow: 0px 4px 50px rgba($black, 0.08);
}

.card-rounded {
  border-radius: 4px;
}

.card-header {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: center;
  padding: 20px 27px 28px 16px;

  @include media-breakpoint-down(3xs) {
    padding: 20px 20px 4px;
    min-height: 56px;
  }

  &::after {
    background: linear-gradient(180deg, $black 0%, $transparent 100%);
    content: "";
    top: 100%;
    left: 0;
    right: 0;
    height: 2px;
    position: absolute;
    opacity: 0.12;
  }

  &__back {
    left: 0;
    margin-left: 16px;
    position: absolute;
  }

  &__back > button {
    background-color: $white;
    border: none;    
  }
}