.homepage-difference {
  background-color: rgba($dodger-blue, 0.05);
  padding: 72px 0 100px;

  @include media-breakpoint-down(lg) {
    padding: 112px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  &__wrapper {
    max-width: 1185px;
    padding: 0 40px;

    @include media-breakpoint-down(lg) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 12px;
    }
  }

  &__item {
    @include media-breakpoint-down(sm) {
      box-shadow: 0px 0px 50px 4px rgba(0, 12, 51, 0.08);
    }
  }

  &__title {
    font-size: $font-size-base * 3;

    @include media-breakpoint-down(md) {
      font-size: $font-size-base * 1.625;
    }
  }

  &__shadow {
    box-shadow: 0px -4px 24px rgba($black, 0.08);
    position: relative;

    @include media-breakpoint-down(sm) {
      box-shadow: none;
    }

    &::before,
    &::after {
      background-color: $white;
      content: "";
      display: block;
      height: 50px;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::before {
      top: -24px;
    }

    &::after {
      bottom: 0;
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    &--odd {
      &::before,
      &::after {
        background-color: $alice-blue;
      }
    }

    &.top-0 {
      &::before {
        top: 0;
      }
    }
  }

  &__gradient {
    background: linear-gradient(90deg, $dodger-blue 0%, $pelorous 52.01%, $bright-sun 100%);
    position: absolute;
    height: 8px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &__middle {
    @include media-breakpoint-up(md) {
      max-width: 320px;
      flex: 2;
      z-index: 2;
    }

    @include media-breakpoint-up(lg) {
      max-width: 410px;
    }
  }

  &__divider {
    @include media-breakpoint-up(md) {
      position: relative;

      &::before {
        content: "";
        background-color: $dodger-blue;
        position: absolute;
        height: 1px;
        margin: 0 auto;
        max-width: 230px;
        width: 75%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
      }
    }
  }
}
