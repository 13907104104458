.select-user {
  &__item {
    border: 1px solid rgba($dodger-blue, 0.5);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 21px 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn {
    border-radius: 4px;
    font-size: $font-size-small;
    min-width: 105px;
    padding: 7px 9px 8px;
    position: relative;

    & > .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }
  }
}