.landing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $transparent;
  padding: 24px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9;
  min-height: 96px;

  @include media-breakpoint-down(md) {
    padding: 12px;
  }

  &__logo {
    &__color {
      display: none;
    }

    &__mobile {
      &__color {
        display: none;
      }
    }
  }

  &__btn {
    visibility: hidden;
    
    &.is-btn-scrolling {
      visibility: inherit;
    }

    & > .btn {
      background-color: transparent;
      border: 1px solid $dodger-blue;
      color: $dodger-blue;
      font-size: $font-size-large;
      padding: 11px 50px;
      font-weight: $font-weight-bold;
    }
  }

  &__left,
  &__right {
    @include media-breakpoint-down(md) {
      min-width: 40px;
    }
  }

  &__middle {
    .landing-header {
      &__btn {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }

  &__right {
    .landing-header {
      &__btn {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  &.is-scrolling {
    background-color: $white;

    &::after {
      background: linear-gradient(180deg, rgba($black, 0.18) 0%, $transparent 83.33%);
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 2px;
    }

    .landing-header__logo {
      &__color {
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
      &__mobile {
        &__color {
          @include media-breakpoint-down(sm) {
            display: block;
          }
        }
      }
    }
  }
}
