.react-autosuggest {
  &__container {
    position: relative;
    width: 100%;
  }

  &__suggestions-container {
    background: $white;
    box-shadow: 0px 0px 24px rgba($black, 0.12);
    border-radius: 0 0 8px 8px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &__suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__suggestion {
    border-top: 1px solid $athens-gray;
    padding: $input-padding-y $input-padding-x;

    &--first {
      border-top-color: $transparent;
    }
  }
}
