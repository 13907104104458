.landing-difference {
  padding: 160px 0;

  @include media-breakpoint-down(lg) {
    padding: 112px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 45px 0 96px;
  }

  @include media-breakpoint-down(sm) {
    padding: 64px 0 76px;
  }

  &__wrapper {
    padding: 0 40px;

    @include media-breakpoint-down(lg) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 12px;
    }
  }

  &__item {
    @include media-breakpoint-down(sm) {
      box-shadow: 0px 0px 50px 4px rgba(0, 12, 51, 0.08);
    }
  }

  &__title {
    font-size: $font-size-base * 3;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base * 2.5;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 2;
    }
  }

  &__shadow {
    box-shadow: 0px 0px 50px 4px rgba(0, 12, 51, 0.08);
    position: relative;

    @include media-breakpoint-down(sm) {
      box-shadow: none;
    }

    &::before,
    &::after {
      background-color: $white;
      content: "";
      display: block;
      height: 50px;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    &--odd {
      &::before,
      &::after {
        background-color: $alice-blue;
      }
    }
  }
}
