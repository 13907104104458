.dnd-zone {
  border: 2px dashed rgba($dodger-blue, 0.5);
  background-color: $alice-blue-alt;
  color: $dodger-blue;
  padding: 16px;
  transition: all 0.3s ease-in-out;

  &:focus {
    outline: none;
  }

  &__content {
    background: linear-gradient(to right, $dodger-blue 3px, transparent 3px) 0 0,
      linear-gradient(to right, $dodger-blue 3px, transparent 3px) 0 100%,
      linear-gradient(to left, $dodger-blue 3px, transparent 3px) 100% 0,
      linear-gradient(to left, $dodger-blue 3px, transparent 3px) 100% 100%,
      linear-gradient(to bottom, $dodger-blue 3px, transparent 3px) 0 0,
      linear-gradient(to bottom, $dodger-blue 3px, transparent 3px) 100% 0,
      linear-gradient(to top, $dodger-blue 3px, transparent 3px) 0 100%,
      linear-gradient(to top, $dodger-blue 3px, transparent 3px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 9px 0 13px;
    position: relative;
  }

  &.has-files {
    background-color: $hint-of-green;
    border-color: $salem;
    color: $salem;
    .dnd-zone__content {
      background: linear-gradient(to right, $japanese-laurel 3px, transparent 3px) 0 0,
      linear-gradient(to right, $japanese-laurel 3px, transparent 3px) 0 100%,
      linear-gradient(to left, $japanese-laurel 3px, transparent 3px) 100% 0,
      linear-gradient(to left, $japanese-laurel 3px, transparent 3px) 100% 100%,
      linear-gradient(to bottom, $japanese-laurel 3px, transparent 3px) 0 0,
      linear-gradient(to bottom, $japanese-laurel 3px, transparent 3px) 100% 0,
      linear-gradient(to top, $japanese-laurel 3px, transparent 3px) 0 100%,
      linear-gradient(to top, $japanese-laurel 3px, transparent 3px) 100% 100%;
      background-repeat: no-repeat;
      background-size: 16px 16px;
    }
  }
  
  &.is-not-supported {
    background-color: rgba($west-side, 0.12);
    border-color: $pizazz;
    color: $pizazz;
    .dnd-zone__content {
      background: linear-gradient(to right, $pizazz 3px, transparent 3px) 0 0,
        linear-gradient(to right, $pizazz 3px, transparent 3px) 0 100%,
        linear-gradient(to left, $pizazz 3px, transparent 3px) 100% 0,
        linear-gradient(to left, $pizazz 3px, transparent 3px) 100% 100%,
        linear-gradient(to bottom, $pizazz 3px, transparent 3px) 0 0,
        linear-gradient(to bottom, $pizazz 3px, transparent 3px) 100% 0,
        linear-gradient(to top, $pizazz 3px, transparent 3px) 0 100%,
        linear-gradient(to top, $pizazz 3px, transparent 3px) 100% 100%;
      background-repeat: no-repeat;
      background-size: 16px 16px;
    }
  }

  &__re-upload {
    background-color: $japanese-laurel;
    border-radius: 2px;
    color: $white;
    font-size: $font-size-base * 0.6875;
    padding: 9px 12.5px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
