.financial-info {
  position: relative;
  @include media-breakpoint-down(sm) {
    height: auto !important;
  }
  &__loader {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}
