.main-modal {
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1100px;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  text-align: left;
  position: relative;
  border-radius: 8px;
  &__overlay {
    background-color: rgba(113, 117, 141, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 20px;
    z-index: 1050;
    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 2px 74px 0 rgba($black, 0.5);
    border-radius: 8px;
    padding: 43px 61px 68px;
    outline: 0;
    width: 100%;
    position: relative;
    @include media-breakpoint-down(xs) {
      padding: 50px 20px;
    }
  }
  &__close {
    background-color: transparent;
    position: absolute;
    top: 22px;
    right: 22px;
    border: none;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
  }
  &--small {
    max-width: 500px;
    .main-modal__content {
      padding: 0;
    }
  }
  &--landing {
    border-radius: 8px;
    max-width: 520px;

    &__form {
      max-width: 320px;
    }

    &__title {
      line-height: 1.6666666667;
    }
  }
}
