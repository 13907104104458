.invoice-checkbox {
  cursor: pointer;
  &__content {
    height: 100%;
    position: relative;
  }

  &__fake-input {
    background: rgba($zumthor, 0.5);
    border: 1.5px solid $dodger-blue;
    border-radius: 4px;
    display: inline-block;
    height: 28px;
    width: 28px;
    transition: all 0.2s ease-in-out;
    &__icon {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  &__input:checked {
    & ~ .invoice-checkbox {
      &__content {
        .invoice-checkbox__fake-input {
          background-color: $dodger-blue;

          &__icon {
            opacity: 1;
          }
        }
      }
    }
  }
}

.funnel-card--invoice {
  @include media-breakpoint-down(sm) {
    box-shadow: none;
    overflow: initial;
  }
  .funnel-card__wrapper {
    padding-top: 40px;
    padding-bottom: 24px;

    @include media-breakpoint-down(sm) {
      padding: 48px 7.5px 56px;
    }
  }
  .funnel-card__content {
    @include media-breakpoint-down(sm) {
      overflow: initial;
    }
  }
}

.card--invoice {
  @include media-breakpoint-down(3xs) {
    background-color: $white;
    box-shadow: 1px 1px 12px rgba(black, 0.24);
    padding: 0 0 64px;
  }
}

.invoice-resume {
  padding: 0 30px;

  &.is-sticky {
    background-color: $white;
    border-top: 1px solid #E6E8F0;
    bottom: 0;
    padding: 16px 30px 0;
    margin: 0 15px;
    position: sticky;

    @include media-breakpoint-down(sm) {
      margin: 0 -8px;
      padding-bottom: 16px;
    }
  }
}
