$font-sizes: () !default;
$font-sizes: map-merge(
  (
    "xsmaller": $font-size-xsmaller,
    "xsmall": $font-size-xsmall,
    "smaller": $font-size-smaller,
    "small": $font-size-small,
    "base": $font-size-base,
    "large": $font-size-large,
    "larger": $font-size-larger,
    "xlarge": $font-size-xlarge,
    "xlarger": $font-size-xlarger,
  ),
  $font-sizes
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $size, $value in $font-sizes {
      .text#{$infix}-#{$size} {
        font-size: $value;
      }
    }
  }
}

$custom-colors: () !default;
$custom-colors: map-merge(
  (
    "white": $white,
    "black": $black,
    "transparent": $transparent,
    "mercury": $mercury,
    "french-gray": $french-gray,
    "bombay": $bombay,
    "spun-pearl": $spun-pearl,
    "gray": $gray,
    "comet": $comet,
    "tundora": $tundora,
    "tuna": $tuna,
    "charade": $charade,
    "alice-blue": $alice-blue,
    "alice-blue-alt": $alice-blue-alt,
    "alice-blue-alt-2": $alice-blue-alt-2,
    "alice-blue-alt-3": $alice-blue-alt-3,
    "onahau": $onahau,
    "onahau-alt": $onahau-alt,
    "dodger-blue": $dodger-blue,
    "dodger-blue-alt": $dodger-blue-alt,
    "sapphire": $sapphire,
    "pizazz": $pizazz,
    "turquoise": $turquoise,
    "trout": $trout,
    "alabaster": $alabaster,
    "alabaster-alt": $alabaster-alt,
    "jumbo": $jumbo,
    "silver": $silver,
    "bright-sun": $bright-sun,
    "martinique": $martinique,
    "tuna-alt": $tuna-alt,
    "burnt-sienna": $burnt-sienna,
    "pattens-blue": $pattens-blue,
    "athens-gray": $athens-gray,
    "cornflower-blue": $cornflower-blue,
    "sugar-cane": $sugar-cane,
    "salem": $salem,
    "iron": $iron,
    "emperor": $emperor,
    "alto": $alto,
    "malibu": $malibu,
    "early-dawn": $early-dawn,
    "lisbon-brown": $lisbon-brown,
    "hint-of-green": $hint-of-green,
    "japanese-laurel": $japanese-laurel,
    "mariner": $mariner,
    "wild-sand": $wild-sand,
    "eastern-blue": $eastern-blue,
    "pale-sky": $pale-sky,
    "west-side": $west-side,
    "mustard": $mustard,
    "jungle-green": $jungle-green,
    "royal-blue": $royal-blue,
    "storm-gray": $storm-gray,
    "flush-mahogany": $flush-mahogany,
    "linen": $linen,
    "stiletto": $stiletto,
    "island-spice": $island-spice,
    "zircon": $zircon,
    "bali-hai": $bali-hai,
  ),
  $custom-colors
);

@each $color, $value in $custom-colors {
  .link-#{$color} {
    color: #{$value};
    &:hover {
      color: darken($value, 15%);
    }
  }
}

@each $color, $value in $custom-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $custom-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $custom-colors {
      @include text-emphasis-variant(".text#{$infix}-#{$color}", $value);
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $custom-colors {
      @include bg-variant(".bg#{$infix}-#{$color}", $value);
    }
  }
}

.img-multiply {
  mix-blend-mode: multiply;
}

.btn-icon {
  appearance: none;
  background: none;
  border: 0 none;
  padding: 0;
}

.line-height-0 {
  line-height: 0;
}

.line-height-1 {
  line-height: 1;
}

.text-pre {
  white-space: pre;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.letter-spacing {
  letter-spacing: 0.4px;
}

.clickable {
  cursor: pointer;
}

.large-gutters {
  margin-right: -30px;
  margin-left: -30px;
  & > .col,
  & > [class*="col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.small-gutters {
  margin-right: -8px;
  margin-left: -8px;
  & > .col,
  & > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.smaller-gutters {
  margin-right: -4px;
  margin-left: -4px;
  & > .col,
  & > [class*="col-"] {
    padding-right: 4px;
    padding-left: 4px;
  }
}

.minh-100 {
  min-height: 100%;
}

.min-width-0 {
  min-width: 0;
}

.font-family-alt {
  font-family: 'Helvetica Neue', sans-serif;
}
