.landing-faq {
  padding-bottom: 120px;
  font-weight: $font-weight-light;
  
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2;
    font-weight: $font-weight-normal;
    padding-bottom: 90px;
    padding-top: 62px;
  }

  &__wrapper {
    max-width: 1144px;
  }

  &__title {
    font-size: $font-size-base * 3;

    @include media-breakpoint-down(md) {
      font-size: $font-size-base * 2;
    }

  }
}
