.switch {
  align-items: center;
  background-color: $comet;
  border-radius: 16px;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: $font-size-xsmall;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 2px;
  position: relative;
  text-transform: uppercase;
  height: 32px;
  width: 56px;
  transition: 0.15s ease all;
  user-select: none;

  &::after {
    background-color: $white;
    border-radius: 14px;
    content: "";
    height: 28px;
    width: 28px;
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
     transition: 0.15s ease all;
  }

  &-checkbox {
    &:checked + .switch {
      background-color: $dodger-blue;
    }
    &:checked + .switch::after {
      left: calc(100% - 28px - 2px);
    }
  }

  .on,
  .off {
    flex: 1;
    text-align: center;
  }
}
