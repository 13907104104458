.loading {
  &__bullet {
    background-color: red;
    border-radius: 100px;
    display: inline-block;
    height: 8px;
    margin: 0 6px;
    width: 8px;
    animation:coloring  1s linear infinite;
    animation-fill-mode: both;
    &:nth-child(1) {
      background-color: #4D7EFE;
      animation-delay: .5s
    }
    &:nth-child(2) {
      background-color: #2CD6C1;
      animation-delay: 1s
    }
    &:nth-child(3) {
      background-color: #F59400;
      animation-delay: 1.5s
    }
    &:nth-child(4) {
      background-color: $bright-sun;
      animation-delay: 2s
    }
  }
}


@keyframes coloring {
  0% {
    background-color: #4D7EFE;
  }
  25% {
    background-color: $bright-sun;
  }
 50% {
    background-color: #F59400;
  }
  75% {
    background-color: #2CD6C1;
  }    
  100%{
    background-color:#4D7EFE;
  }
}