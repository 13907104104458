.payment-plan {
  &__box {
    @include media-breakpoint-down(3xs) {
      margin-left: -11px;
      margin-right: -11px;
    }
    &__hr {
      border-color: rgba($dodger-blue, 0.12);
      margin: 20px 0;
    }
    &__divider {
      position: relative;
      &::after {
        content: "";
        background-color: rgba($dodger-blue, 0.12);
        height: 100%;
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
      }
      &--none-mobile {
        &::after {
          @include media-breakpoint-down(3xs) {
            display: none;
          }
        }
      }
    }
    &__savings {
      background-color: $onahau-alt;
      border-radius: 100px;
      @include media-breakpoint-down(3xs) {
        text-transform: uppercase;
        font-size: $font-size-base * 0.6875;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 12px);
      }
    }
    &__amount {
      font-size: $font-size-base * 3;
    }
  }

  &-partner {
    padding-left: 60px;

    &__icon {
      position: absolute;
      top: 0;
      right: calc(100% + 10px);

      @include media-breakpoint-down(sm) {
        max-width: 45px;
        right: calc(100% + 6px);
      }
    }
  }

  &__option {
    appearance: none;
    background: none;
    border: 1px solid rgba(69, 112, 254, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 0px 10px 8px;
    margin-bottom: 10px;
    width: 100%;
    
    @include media-breakpoint-up(2xs) {
      padding: 18px 10px 10px 14px;
    }
  }

  &__tag {
    border-radius: 4px;
    color: $white;
    padding: 4px 9px;
    min-width: 92px;
    text-align: center;
  }
}
