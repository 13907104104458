.document {
  max-width: breakpoint-max(sm);
  margin: 50px auto;
  background: white;
  padding: 30px;
  width: 100%;

  &__loader {
    max-width: breakpoint-max(sm);
    //background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    margin: 50px auto;
  }
}
