.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal > * {
  // When the modal is closed, overlay div has no css class
  // This selector should be overridden by the `&--after-open` class below
  opacity: 0;
}
.ReactModal__Overlay {
  overflow-y: auto;
  transition: 0.3s ease-out;
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  transition: transform 0.3s ease-out;
  transform: translate(0%, -200%);
  &--after-open {
    transform: translate(0%, 0%);
  }
  &--before-close {
    transform: translate(0%, -200%);
  }
}
