@font-face {
  font-family: "Roboto";
  src: url("../../fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../../fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/roboto-regularitalic-webfont.woff2") format("woff2"),
    url("../../fonts/roboto-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-bold-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-bolditalic-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-light-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-lightitalic-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-regular-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-regularitalic-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-regularitalic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-medium-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-semibold-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../../fonts/nunito-semibolditalic-webfont.woff2") format("woff2"),
    url("../../fonts/nunito-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
