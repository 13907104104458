.table-fixed {
  table-layout: fixed;
}

.table-custom {
  height: 100%;
  &__head {
    position: sticky;
    top: 0;
    .table {
      table-layout: fixed;
      thead th {
        background-color: $white;
        border-bottom-width: 1px;
      }
    }
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    .table {
      table-layout: fixed;

      tbody {
        tr {
          td {
            border-top-width: 0;
          }
        }
      }
    }
  }

  &--financials {
    .table {
      tr {
        th,
        td {
          &:nth-child(1) {
            width: calc(86px + 24px);
          }
          &:nth-child(2) {
            width: calc(90px + 24px);
          }
          &:nth-child(3) {
            width: calc(137px + 24px);
          }
          &:nth-child(4) {
            width: calc(157px + 24px);
          }
          &:nth-child(5) {
            width: calc(76px + 24px);
          }
          &:nth-child(6) {
            width: calc(60px + 24px);
          }
          &:nth-child(7) {
            width: calc(85px + 24px);
          }
          &:nth-child(8) {
            width: calc(89px + 24px);
          }
        }

        &:hover {
          td {
            background-color: $alice-blue-alt;
          }
        }
      }
    }
  }

  &--search-results {
    .table {
      tr {
        th,
        td {
          font-size: $font-size-small;
          &:nth-child(1) {
            width: calc(69px + 24px);
          }
          &:nth-child(2) {
            width: calc(65px + 24px);
          }
          &:nth-child(3) {
            width: calc(76px + 24px);
          }
          &:nth-child(4) {
            width: calc(96px + 24px);
          }
          &:nth-child(5) {
            width: calc(150px + 24px);
          }
          &:nth-child(6) {
            width: calc(96px + 24px);
          }
          &:nth-child(7) {
            width: calc(68px + 24px);
          }
          &:nth-child(8) {
            width: calc(65px + 24px);
          }
          &:nth-child(9) {
            width: calc(100px + 24px);
          }
          &:nth-child(10) {
            width: calc(76px + 24px);
          }
        }
      }
    }
  }
}

.table-bill {
  tbody {
    tr {
      th {
        color: $comet;
        font-weight: $font-weight-medium;
        font-size: $font-size-smaller;
        padding-top: 6px;
        padding-left: 0;
        width: 200px;
        @include media-breakpoint-down(2xs) {
          width: 125px;
        }
      }
    }
  }
}
