.error-page {
  max-width: 448px;
  padding: 84px 24px 88px;

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    min-height: 540px;
    padding: 60px 24px 40px;
  }

  &__icon {
    font-size: $font-size-base * 4.5;
  }

  .btn {
    max-width: 327px;
    margin: 0 auto;
  }

  &.tu-error-page {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      min-height: 0;
      padding: 32px 24px 40px;
    }
  }
}
