.form-group {
  position: relative;
  &.input-group {
    & > .form-control {
      z-index: initial;
    }
    .input-group-prepend {
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      margin: 0;
      z-index: 1;
      & ~ .form-control {
        border-radius: $input-border-radius;
        padding-left: $input-padding-x * 3;
      }
    }
    .input-group-text {
      border-width: 0;
      padding: 0 0 0 $input-padding-x;
      font-size: $font-size-base * 2;
    }
    .form-control-placeholder {
      left: $input-padding-x * 3;
    }
  }

  .optional-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $input-padding-x;
  }
}

.input-group-round {
  & > .form-control {
    border-radius: 100px 0 0 100px;
    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }
}

.form-control {
  transition: all 200ms;
  &::placeholder {
    color: $transparent;
    opacity: 0;
  }
  &:focus,
  &:not(:placeholder-shown) {
    padding: $input-padding-y * 1.25 $input-padding-x $input-padding-y * 0.625;
  }
  &--search {
    border-radius: 100px !important;
    padding-left: 55px;
    &:focus,
    &:not(:placeholder-shown) {
      padding: $input-padding-y $input-padding-x $input-padding-y 55px;
    }
    &:focus {
      & ~ .input-group-prepend {
        .input-group-text {
          color: $input-focus-border-color;
        }
      }
    }
    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }
  &--without-label {
    padding: $input-padding-y $input-padding-x;
    &:focus,
    &:not(:placeholder-shown) {
      padding: $input-padding-y $input-padding-x;
    }
  }

  &--with-placeholder {
    &:focus {
      &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
      }
    }
  }

  &--select {
    background-color: $white;
    appearance: none;
  }

  &:disabled,
  &.is-disabled {
    color: $input-disabled-color;
    border-color: $input-disabled-border-color;
    background-color: $input-disabled-bg;
  }

  &-placeholder {
    color: $input-placeholder-color;
    position: absolute;
    top: 50%;
    left: $input-padding-x;
    transform: translateY(-50%);
    transition: all 200ms;
    margin: 0;
  }

  &:focus ~ .form-control-placeholder,
  &:not(:placeholder-shown) ~ .form-control-placeholder {
    font-size: $font-size-xsmall;
    text-transform: uppercase;
    top: 7px;
    transform: translate(0, 0);
    opacity: 1;
  }

  &:not(:placeholder-shown) ~ .optional-label {
    display: none;
  }

  &.is-invalid {
    color: $pizazz;
    border-color: $pizazz;
    box-shadow: 0px 0px 4px rgba($pizazz, 0.12);
    background-image: url("../../images/is-invalid.svg");
    padding: $input-padding-y * 1.25 $input-padding-x * 3.3333333333 $input-padding-y * 0.625 $input-padding-x;
    &:focus {
      border-color: $pizazz;
      box-shadow: 0px 0px 4px $pizazz;
    }
    &-inside {
      background-image: none;
      color: $charade;
    }
  }

  &.is-valid {
    border-color: $input-border-color;
    background-image: url("../../images/is-valid.svg");
    padding: $input-padding-y * 1.25 $input-padding-x * 3.3333333333 $input-padding-y * 0.625 $input-padding-x;
    &:focus {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
    &:disabled {
      background-image: none;
    }
  }

  &--encrypted {
    &.is-encrypted {
      color: transparent;
      transition: none;
    }

    & ~ .toggle-button {
      background-color: $dodger-blue;
      color: $white;
      border: 0 none;
      border-radius: 100px;
      position: absolute;
      top: 11.5px;
      right: 10px;
      height: 32px;
      width: 32px;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.is-active {
        background-color: $alice-blue;
        color: $dodger-blue-alt;
      }
    }

    & ~ .fake-text-encrypted {
      position: absolute;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.4375;
      color: inherit;
      background-color: $white;
      top: 1.17188rem;
      left: 0.9375rem;
      bottom: 0.58594rem;
      letter-spacing: 1px;
      &:after {
        content: "";
        display: inline-block;
        height: 24px;
        border-right: 1px solid $transparent;
        animation: blink 0.5s step-end infinite alternate;
        vertical-align: middle;
      }
    }

    &:focus {
      & ~ .fake-text-encrypted {
        &:after {
          border-color: $black;
        }
      }
    }

    &.is-invalid {
      & ~ .fake-text-encrypted {
        color: $pizazz;
      }
      &:focus ~ .fake-text-encrypted {
        &:after {
          border-color: $pizazz;
        }
      }
    }

    @keyframes blink {
      50% {
        border-color: $white;
      }
    }
  }
}

textarea.form-control {
  & ~ .form-control-placeholder {
    top: 16px;
    transform: translateY(0);
  }
}

.invalid-feedback {
  display: block;
  background-color: rgba($pizazz, 0.08);
  color: $pizazz;
  font-size: $font-size-base;
  padding: 16px 20px;
  margin-top: -9px;
  margin-bottom: 1rem;
  text-align: left;
  &--inside-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    display: inline-block !important;
    width: auto;
    background: none;
    font-size: $font-size-small;
    padding: $input-padding-y 9px;
    line-height: 23px;
  }
}

.form-checkbox {
  display: inline-flex;
  &__label {
    background-color: rgba($zumthor, 0.5);
    border: 1.5px solid $dodger-blue;
    box-sizing: border-box;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    position: relative;
    transition: all 0.2s ease-in-out;
    &__icon {
      color: $transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  &__input {
    display: none;
    &:checked {
      & ~ .form-checkbox__label {
        background-color: $dodger-blue;
        .form-checkbox__label__icon {
          color: $white;
          font-size: $font-size-smaller;
        }
      }
    }
  }
  &.is-invalid {
    .form-checkbox__label {
      border-color: $pizazz;
    }
  }
}

.form-radio {
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-align: left;
    .fake-radio {
      display: flex;
      position: relative;
      &::before {
        background-color: rgba($zumthor, 0.5);
        border: 1.5px solid $dodger-blue;
        box-sizing: border-box;
        border-radius: 100px;
        content: "";
        display: inline-block;
        height: 32px;
        position: relative;
        width: 32px;
      }
      &::after {
        background-color: $transparent;
        border-radius: 100px;
        content: "";
        height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: background-color 0.2s ease-in-out;
        width: 0;
        transform: translate(-50%, -50%);
      }

      &--sm {
        &::before {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  &__input {
    display: none;
    &:checked {
      & ~ .form-radio__label {
        .fake-radio {
          &::after {
            background-color: $dodger-blue;
            height: 20px;
            width: 20px;
          }

          &--sm {
            &::after {
              height: 15px;
              width: 15px;
            }
          }
        }
      }
    }
  }
}

input::placeholder {
  color: $input-placeholder-color;
  opacity: 1;
}

.simple-input {
  border: 1px solid #cdcdcf;
  border-radius: 2px;
  background-color: white;
  color: #2d2e37;
  height: 40px;
  padding: 8px 12px;
  width: 100%;
  -webkit-font-smoothing: auto;

  &:focus {
    color: #2d2e37;
    background-color: #fff;
    border-color: #4570fe;
    outline: 0;
    box-shadow: 0px 0px 4px #4570fe;
  }

  &.is-invalid {
    color: $pizazz;
    border-color: $pizazz;
  }
}

.simple-label {
  color: $comet;
  font-size: $font-size-smaller;
  margin-bottom: 2px;
}

.form-nmi {
  min-height: 205px;
}

.loading-nmi {
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
