.homepage-let-us-help {
  background-color: rgba($dodger-blue, 0.05);

  &__description {
    font-size: $font-size-base * 3;
    line-height: 1.4166666667;
    
    @include media-breakpoint-down(lg) {
      font-size: $font-size-base * 2.5;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 2;
    }

    &:focus {
      outline: none;
    }
  }

  &__item {
    &:focus {
      outline: none;
    }
  }

  &__dots {
    & > li {
      height: 12px;
      width: 12px;
      margin: 0 4px;

      button {
        height: inherit;
        width: inherit;
        padding: 0;

        &::before {
          font-size: 12px;
          height: inherit;
          line-height: 1em;
          width: inherit;
          color: $iron;
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          &::before {
            color: $dodger-blue-alt;
            opacity: 1;
          }
        }
      }
    }
  }
}
