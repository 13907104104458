.landing-footer {
  background-color: $tuna;
  background-image: url("../../../images/landing/bg_footer.svg");
  background-repeat: no-repeat;
  background-position: calc(100% + 288px) 57%;
  color: $alto;
  padding: 180px 0 80px;
  position: relative;

  &::before {
    background: linear-gradient(90deg, $dodger-blue 0%, $pelorous 52.01%, $bright-sun 100%);
    content: "";
    position: absolute;
    height: 8px;
    width: 100%;
    bottom: 100%;
    left: 0px;
    right: 0px;
  }

  @include media-breakpoint-down(sm) {
    background-image: none;
    padding: 40px 0 44px;
  }

  &__logo {
    max-width: 128px;

    @include media-breakpoint-down(sm) {
      max-width: 102px;
      padding-top: 22px;
    }
  }

  &__menu {
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 1.7777777778;
    margin-bottom: 148px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: initial;
      max-width: 256px;
      margin: 0 auto 22px;
    }

    &__item {
      padding: 0 114px;

      @include media-breakpoint-down(md) {
        padding: 0 57px;
      }

      @include media-breakpoint-down(sm) {
        padding: 32px 0;
      }

      &:first-child {
        @include media-breakpoint-down(sm) {
          order: 3;
        }
      }

      &:nth-child(even) {
        &::before,
        &::after {
          background-color: rgba($white, 0.24);
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
      &:last-child {
        position: relative;
        @include media-breakpoint-down(sm) {
          &::before,
          &::after {
            background-color: rgba($white, 0.24);
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
          }
          &::before {
            left: 0;
            right: 0;
            top: 0;
          }
          &::after {
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  &__copyright {
    letter-spacing: 0.04em;
  }
}
