.homepage-banner {
  background-image: url("../../../images/homepage/bg-banner.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 64px 0;
  position: relative;
  
  @include media-breakpoint-down(sm) {
    background-image: url("../../../images/homepage/bg-banner-mobile.jpg");
    padding: 48px 0 0;
  }

  &__wrapper {
    max-width: 1024px;
    padding: 0;
  }

  &__title {
    font-size: $font-size-base * 5.25;
    line-height: 1.1666666667;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base * 3.5;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 2.5;
    }
  }

  &__subtitle {
    font-size: $font-size-base * 1.5;
    line-height: 1.375;
    
    @include media-breakpoint-down(md) {
      font-size: $font-size-large;
    }
  }

  &__form {
    border-radius: 16px;
    padding: 30px 43px 37px;
    
    @include media-breakpoint-down(sm) {
      border-radius: 0;
      padding: 30px 15px;
      margin: 0 -15px;
    }
  }

  &__btn {
    border-radius: 4px;
    height: 64px;
  }

  &__legend {
    position: relative;

    & > .img {
      position: absolute;
      right: calc(100% + 10px);
      bottom: 50%;

      @include media-breakpoint-down(sm) {
        bottom: -15px;
        right: calc(100% - 40px);
      }
    }
  }

  &__illustration {
    position: relative;
    height: 100%;

    @include media-breakpoint-down(lg) {
      padding: 0 80px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 24px;
    }

    .sun,
    .illustration {
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .illustration {
      z-index: 1;

      @include media-breakpoint-down(lg) {
        right: 104px;
      }

      @include media-breakpoint-down(md) {
        right: 0;
      }
    }
  }

  .scroll-down-icon {
    position: absolute;
    left: 50%;
    bottom: 25px;
    width: 0.7rem;
    height: 0.7rem;
    border-bottom: 3px solid $white;
    border-right: 3px solid $white;
    z-index: 9;
    transform: translate(-50%, 0%) rotate(45deg);
    animation: fade_move_down 4s ease-in-out infinite;
    
    @include media-breakpoint-up(md) {
      bottom: 50px;
      width: 1.5rem;
      height: 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}

.homepage-banner--overall {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .homepage-banner {
    flex: 1;
  }

  @include media-breakpoint-down(md) {
    min-height: 0;
  }
}
