.landing-banner {
  padding: 64px 0 356px;

  @include media-breakpoint-down(md) {
    padding: 48px 0 264px;
  }

  @include media-breakpoint-down(sm) {
    padding: 48px 0 32px;
  }

  &__wrapper {
    max-width: 1024px;
    padding: 0;
  }

  &__title {
    font-size: $font-size-base * 4;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base * 3;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 2.5;
    }
  }

  &__subtitle {
    line-height: 1.6363636364;
  }

  &__form {
    max-width: 400px;

    &-control {
      border-radius: 4px;
      height: 64px;
    }
  }

  &__btn {
    border-radius: 4px;
    height: 64px;
  }

  &__legend {
    position: relative;

    & > .img {
      position: absolute;
      right: calc(100% + 10px);
      bottom: 50%;

      @include media-breakpoint-down(sm) {
        bottom: -15px;
        right: calc(100% - 40px);
      }
    }
  }

  &__illustration {
    position: relative;
    height: 100%;

    @include media-breakpoint-down(lg) {
      padding: 0 80px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 24px;
    }

    .sun,
    .illustration {
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .illustration {
      z-index: 1;

      @include media-breakpoint-down(lg) {
        right: 104px;
      }

      @include media-breakpoint-down(md) {
        right: 0;
      }
    }
  }
}
