.tabs {
  &__tab-list {
    border-bottom: 1px solid $iron;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 48px;
  }
  &__tab {
    color: $trout;
    cursor: pointer;
    flex: 1;
    font-size: $font-size-base * 0.8125;
    letter-spacing: 0.4px;
    padding: 20px;
    text-align: center;
    &.is-active {
      color: $dodger-blue;
      font-weight: $font-weight-medium;
      position: relative;
      &:after {
        background-color: $dodger-blue;
        content: "";
        position: absolute;
        height: 2px;
        bottom: -1px;
        left: 0;
        right: 0;
      }
    }
  }
  &__tab-panel {
    &.is-active {
    }
  }
}
