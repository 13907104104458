.landing-steps {
  background-color: $mariner;
  color: $white;
  padding: 53px 0 148px;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding: 0 0 102px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  &::before {
    clip-path: ellipse(50% 296px at 50% 296px);
    background-color: inherit;
    position: absolute;
    height: 296px;
    width: 140%;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(lg) {
      clip-path: ellipse(80% 209px at 50% 209px);
    }

    @include media-breakpoint-up(md) {
      content: "";
    }
  }

  &__wrapper {
    max-width: 1310px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__title {
    line-height: 1.3333333333;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base * 1.5;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  &__number {
    font-size: $font-size-base * 3.375;
    opacity: 0.6;
  }

  &__item {
    max-width: 334px;
    position: relative;
    margin: 0 16px;
    z-index: 1;

    &--even {
      max-width: 344px;

      @include media-breakpoint-down(sm) {
        background-color: $white;
        color: $charade;
      }
    }

    @include media-breakpoint-down(lg) {
      max-width: 280px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin: 0 auto !important;
      padding: 64px 24px;
    }
  }

  .scroll-down-icon {
    position: absolute;
    left: 50%;
    top: -25px;
    width: 0.7rem;
    height: 0.7rem;
    border-bottom: 2px solid $dodger-blue;
    border-right: 2px solid $dodger-blue;
    z-index: 9;
    transform: translate(-50%, 0%) rotate(45deg);
    animation: fade_move_down 4s ease-in-out infinite;

    @include media-breakpoint-up(md) {
      top: calc(-50% + 20px);
      border-color: $white;
      width: 1rem;
      height: 1rem;
    }
  }
}

@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
