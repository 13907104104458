.homepage-header {
  background-color: $transparent;
  padding: 24px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  @include media-breakpoint-down(md) {
    padding: 18px 0;
    min-height: 0;
  }

  &__logo {
    display: none;

    @include media-breakpoint-down(md) {
     max-width: 120px;
    }
  }

  &__btn {
    visibility: hidden;

    &.is-btn-scrolling {
      visibility: inherit;
    }

    & > .btn {
      padding: 11px 50px;
      font-weight: $font-weight-bold;
      font-size: $font-size-xlarger;

      @include media-breakpoint-down(md) {
        font-size: $font-size-small;
        padding: 7px 35px;
      }
    }
  }

  &__left,
  &__right {
    @include media-breakpoint-down(md) {
      min-width: 40px;
    }
  }

  &.is-scrolling {
    background: rgba($white, 0.95);
    box-shadow: 0px 4px 21px rgba(0, 11, 48, 0.27);

    .homepage-header__logo {
      display: block;
    }
  }
}
