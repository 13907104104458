.homepage-who-we-are {
  &__title {
    font-size: $font-size-base * 2.625;

    @include media-breakpoint-down(md) {
      font-size: $font-size-base * 1.625;
      line-height: 1.3461538462;
     }
  }

  &__image {
    @include media-breakpoint-down(sm) {
      max-width: 75%;
    }
  }
}

.homepage-how-we-do-it {
  &__title {
    font-size: $h3-font-size;

    @include media-breakpoint-down(md) {
      font-size: $h4-font-size;
     }
  }
}
