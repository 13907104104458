.funnel-card {
  background-color: $white;
  border-radius: 0;
  box-shadow: 0px 4px 50px rgba($black, 0.08);
  margin: 0 auto;
 
  @include media-breakpoint-down(sm) {
    box-shadow: 0px 2px 6px rgba($black, 0.12);
  }
 
  &__footer {
    font-size: $font-size-base * 0.6875;
    font-family: "Roboto", sans-serif;
  }
 
  &__content {
    position: relative;
 
    & > .progress {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
 
      @include media-breakpoint-down(3xs) {
        position: fixed;
        top: 56px;
      }
    }

  }
 
  &__wrapper {
    padding: 72px 24px 104px;
    max-width: 560px;
    margin: 0 auto;
 
    @include media-breakpoint-down(sm) {
      padding: 40px 24px 56px;
    }
  }
 
  &__form {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
 
  &__content--bottom {
    position: relative;
    padding: 40px;
 
    &:before {
      background-image: linear-gradient(180deg, rgba($sapphire, 0.08) 0%, $transparent 83.33%);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 8px;
    }
  }
 
  &__banner {
    background-color: $alice-blue-alt-2;
    color: $sapphire;
    font-style: italic;
    letter-spacing: 0.4px;
    padding: 27px 24px;
    position: relative;
    min-height: 96px;

    &::before,
    &::after {
      content: "";
      left: 0;
      position: absolute;
      right: 0;
    }
    
    &::before {
      top: 0;
      background: linear-gradient(360deg, rgba($black, 0.0001) 0%, $black 100%);
      mix-blend-mode: normal;
      opacity: 0.12;
      height: 2px;
    }
    
    &::after {
      background: linear-gradient(180deg, rgba(25, 42, 97, 0) 0%, #192a61 100%);
      opacity: 0.08;
      bottom: 0;
      height: 4px;
    }
    
    @include media-breakpoint-up(sm) {
      margin: 16px 16px 0;
    
      &::before,
      &::after {
        display: none;
      }
      
      & + .funnel-card__wrapper {
        padding-top: 24px;
      }
    }
  }

  &__notification {
    max-width: 432px;
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
      padding: 16px 24px 0;
    }

    &__content {
      background-color: #fff7e3;
      border-radius: 8px;
      color: #281d00;
      max-width: 432px;
      padding: 19px 14px;
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 10px 13px;
      }

      &::after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: $transparent;
        border-top-color: #fff7e3;
        border-width: 10px;
        margin-left: -10px;
      }
    }

    &__icon {
      color: #a17b19;
      font-size: $font-size-base * 2;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-larger;
      }
    }

    & + .funnel-card__wrapper {
      padding-top: 22px;
    }
  }
}