.connect-bank {
  &__loader {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  &__card {
    border-radius: 4px;
    border: 1px solid rgba($dodger-blue, 0.5);
    padding: 28px 20px 15px;
    position: relative;
    overflow: hidden;

    .read-more__button {
      position: relative;
      z-index: 1;
    }
  }
  &__button {
    background-color: $transparent;
    border: 0 none;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &__icon {
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 94px;
    width: 94px;
  }
}

.read-more {
  position: relative;
  margin-left: -11px;
  margin-right: -11px;
  padding: 54px 27px 32px;
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.payment-method-box {
  background: $white;
  border: 1px solid rgba($dodger-blue, 0.5);
  border-radius: 8px;
  
  & > label {
    cursor: pointer;
    min-height: 72px;
  }
}
