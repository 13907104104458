.google-autocomplete {
  position: relative;
  &__dropdown-container {
    background-color: $white;
    border: 1px solid $french-gray;
    box-shadow: 0px 4px 5px rgba($black, 0.08);
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    left: 0;
    z-index: 3;
  }
  &__suggestion-item {
    padding: 5px;
    &--active {
      background-color: $dodger-blue;
      color: $white;
    }
  }
}
