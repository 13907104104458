.counter {
  display: inline-flex;
  font-size: $font-size-base * 1.5;

  & > * {
    background-color: $transparent;
    border: 1px solid $dodger-blue;
    color: $dodger-blue;
    padding: 12px 28px;
    &:first-child,
    &:last-child {
      padding-left: 18px;
      padding-right: 18px;

      &:focus {
        outline: none;
      }
    }
    &:first-child {
      border-radius: 4px 0px 0px 4px;
      margin-right: -1px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
      margin-left: -1px;
    }
  }
}
