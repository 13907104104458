.main-header {
  background-color: $white;
  padding: 29px 27px 22px 25px;

  @include media-breakpoint-down(3xs) {
    padding: 14px 24px 10px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::after {
    background: linear-gradient(180deg, $black 0%, $transparent 100%);
    content: "";
    top: 100%;
    left: 0;
    right: 0;
    height: 2px;
    position: absolute;
    opacity: 0.12;
  }

  &__logo {
    max-width: 110px;
    width: 100%;

    &.large {
      max-width: 220px;
    }
  }

  &__back {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
  }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      & + div {
        padding-left: 16px;
        margin-left: 16px;
        border-left: 1px solid $french-gray;
      }
    }
  }
}
