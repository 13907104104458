.homepage-faq {
  padding: 100px 0 108px;
  font-weight: $font-weight-light;
  
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2;
    font-weight: $font-weight-normal;
    padding: 50px 0;
  }

  &__wrapper {
    max-width: 1144px;
  }

  &__title {
    font-size: $font-size-base * 2.625;

    @include media-breakpoint-down(md) {
      font-size: $font-size-base * 1.625;
    }
  }

  &__subtitle {
    font-size: $font-size-base * 1.75;

    @include media-breakpoint-down(md) {
      font-size: $font-size-larger;
    }
  }
}
