.funnel {
  &-wrapper {
    max-width: $wrapper-funnel;
    padding-top: 80px;
    padding-bottom: 80px;
    @include media-breakpoint-down(3xs) {
      padding: 0 0 48px;
    }

    &-epic {
      padding-top: 20px;
      max-width: $wrapper-funnel;

      @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 0;
      }

      .funnel-card--invoice {
        .funnel-card__wrapper {
          padding-top: 0;
        }
      }
      .funnel-card__wrapper {
        padding-top: 0;
      }
    }

    &--error {
      max-width: 880px;
    }
  }
}
