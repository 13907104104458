.success {
  &__image {
    position: absolute;
    right: -60px;
    bottom: 100%;
  }

  &__code {
    letter-spacing: 3px;
    opacity: 0.8;
    margin-top: 7px;
  }
}