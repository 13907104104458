$wrapper: 1024px;
$wrapper-large: 1440px;

// Colors
$white: #fff;
$black: #000;
$transparent: transparent;

$mercury: #E5E5E5;
$french-gray: #CDCDCF;
$bombay: #B3B4B7;
$spun-pearl: #ABABAF;
$gray: #818181;
$comet: #616377;
$tundora: #4D4D4D;
$tuna: #3A3C47;
$tuna-alt: #31323B;
$charade: #2D2E37;
$alice-blue: #F4FAFF;
$alice-blue-alt: #F0FAFF;
$alice-blue-alt-2: #F6FCFF;
$alice-blue-alt-3: #F5FBFF;
$onahau: #C6E3FF;
$onahau-alt: #C8EBFF;
$dodger-blue: #4570FE;
$dodger-blue-alt: #4D7EFE;
$sapphire: #2C48A2;
$pizazz: #FF8800;
$turquoise: #2CD6C1;
$trout: #4D4F5F;
$alabaster: #FAFAFA;
$alabaster-alt: #F7F7F7;
$jumbo: #818287;
$silver: #C4C4C4;
$zumthor: #EDF6FF;
$bright-sun: #FECA45;
$martinique: #2E2E47;
$burnt-sienna: #EB5757;
$pattens-blue: #E2F5FF;
$athens-gray: #EEEEEF;
$cornflower-blue: #6886E9;
$sugar-cane: #F6FFF4;
$salem: #079137;
$iron: #D9D9DB;
$emperor: #4F4F4F;
$alto: #D0D0D0;
$malibu: #76C3FF;
$early-dawn: #FFF8E6;
$lisbon-brown: #483F27;
$hint-of-green: #F0FFF3;
$japanese-laurel: #0EAD00;
$pelorous: #40C2C5;
$mariner: #2767DD;
$wild-sand: #F6F6F6;
$eastern-blue: #26ACB5;
$pale-sky: #646C82;
$west-side: #FFA011;
$mustard: #FED24F;
$jungle-green: #21A393;
$royal-blue: #5B4EF0;
$storm-gray: #71758D;
$flush-mahogany: #D14343;
$linen: #FDF4F4;
$stiletto: #A73636;
$island-spice: #FFFAED;
$zircon: #FAFBFF;
$bali-hai: #8F95B2;

$primary: $dodger-blue !default;

$brand-success: #19ba9c !default;
$brand-info: #3697da !default;
$brand-warning: #f5a623 !default;
$brand-danger: #e84c3e !default;

//Body
$body-bg: $alabaster-alt !default;
$body-color: $charade !default;

// Fonts
$line-height-base: 1.4375;

$font-family-sans-serif: 'Nunito', sans-serif;

$font-weight-medium: 500;
$font-weight-semibold: 600;

$font-size-base: 1rem !default; //16px;
$font-size-xlarger: $font-size-base * 1.5 !default; //24px;
$font-size-xlarge: $font-size-base * 1.375 !default; //22px;
$font-size-larger: $font-size-base * 1.25 !default; //20px;
$font-size-large: $font-size-base * 1.125 !default; //18px;
$font-size-small: $font-size-base * 0.875 !default; //14px;
$font-size-smaller: $font-size-base * 0.75 !default; //12px;
$font-size-xsmall: $font-size-base * 0.625 !default; //10px;
$font-size-xsmaller: $font-size-base * 0.5 !default; //8px;

$h1-font-size: $font-size-base * 2.125 !default; //34px
$h2-font-size: $font-size-base * 2 !default; //32px
$h3-font-size: $font-size-base * 1.875 !default; //30px
$h4-font-size: $font-size-base * 1.5 !default; //24px
$h5-font-size: $font-size-base * 1.375 !default; //22px
$h6-font-size: $font-size-base * 1.25 !default; //20px;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  2xs: 375px,
  3xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  2xl: 1440px,
  3xl: 1600px
) !default;

// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25), // 4px
    2: ($spacer * .5), // 8px
    3: ($spacer * .75), // 12px
    4: $spacer, // 16px
    5: ($spacer * 1.5), // 24px
    6: ($spacer * 2), // 32px
    7: ($spacer * 2.5), // 40px
    8: ($spacer * 3), // 48px
    9: ($spacer * 3.5), // 56px
    10: ($spacer * 4), // 64px
    11: ($spacer * 4.5), // 72px
    12: ($spacer * 5), // 80px
    13: ($spacer * 5.5), // 88px
    14: ($spacer * 6), // 96px
    15: ($spacer * 6.5), // 102px
    16: ($spacer * 7), // 110px
  ),
  $spacers
);


//Forms
$input-border-radius: 2px;
$input-border-color: $french-gray;
$input-color: $charade;
$input-placeholder-color: $storm-gray;
$input-padding-y: .9375rem;
$input-padding-x: .9375rem;
$input-focus-border-color: $dodger-blue;
$input-focus-box-shadow:  0px 0px 4px $dodger-blue;
$input-disabled-bg: $alabaster;
$input-disabled-color: $jumbo;
$input-disabled-border-color: $bombay;
$input-group-addon-color: $spun-pearl;
$input-group-addon-bg: $transparent;

//Cards
$card-border-width: 0px;
$card-border-color: $transparent;
$card-border-radius: 0;
$card-spacer-x: 1.5rem;

//Progress Bar
$progress-height: 4px;
$progress-bg: $transparent;
$progress-border-radius: 0;
$progress-bar-bg: $dodger-blue;

//Buttons
$btn-border-radius: 100px;
$btn-padding-y: 16px;
$btn-line-height: 1.375;
$btn-font-weight: $font-weight-semibold;
$btn-border-radius-sm: 100px;
$btn-padding-x-sm: 15px;
$btn-padding-y-sm: 1px;

//Links
$link-color: $dodger-blue;
$link-decoration: underline;
$link-hover-color: $dodger-blue-alt;

//Funnel
$wrapper-funnel: 670px;
